/* Author: Agile Pixel */

// Options for Carousel Flickity
var $flickityOptions = {
    imagesLoaded: true,
    cellAlign: 'left',
    percentPosition: true,
    autoPlay: true,
    autoPlay: 7000,
    pauseAutoPlayOnHover: true,
    pageDots: false,
    prevNextButtons: true,
    adaptiveHeight: true,
    arrowShape: {
        x0: 10,
        x1: 60,
        y1: 50,
        x2: 70,
        y2: 40,
        x3: 30
    }
}

// Slide Out Menu Options
var $slideOutMenu = {
    'panel': document.getElementById('panel'),
    'menu': document.getElementById('menu'),
    'padding': 256,
    'tolerance': 70
}

jQuery(document).ready(function($) {

    if ($('.carousel--js').length) {
        // init Carousel
        var flkty = new Flickity('.carousel--js', $flickityOptions);
    }
    if ($('.burger').length) {
        // init Slide Out Js
        var slideout = new Slideout($slideOutMenu);

        //Trigger Slideout on click
        $('.burger').on('click', function() {
            slideout.toggle();
            $(this).toggleClass('active');
        });
    }
    if ($('.main-gallery').length) {
        var flkty = new Flickity('.main-gallery', $flickityOptions);
    }
});